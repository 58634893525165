import React from 'react';

import { AuditOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerItem: IRouter = {
  path: '/item',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'item.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <AuditOutlined />,
  },
  permissionCode: PermissionEnum.ItemsView,
};

export const routerAddItem: IRouter = {
  path: '/item/add',
  loader: React.lazy(() => import('./component/FormItemPage')),
  exact: true,
  name: 'item.add', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.ItemsCreate,
};
export const routerUpdateItem: IRouter = {
  path: '/item/update/:id',
  loader: React.lazy(() => import('./component/FormItemPage')),
  exact: true,
  name: 'item.update', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.ItemsUpdate,
};

export const routerItemInfo: IRouter = {
  path: '/item/info/:id',
  loader: React.lazy(() => import('./component/ItemInfoPage')),
  exact: true,
  name: 'item.info.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: [PermissionEnum.ItemsViewDetail, PermissionEnum.ItemsPurchaseHistoriesView],
  generatePath(id: string, listPermissions: any) {
    // const permissionTabs = [
    //   PermissionEnum.ItemsViewDetail,
    //   PermissionEnum.ItemsPurchaseHistoriesView,
    // ];
    // if (permissionTabs.find(item => listPermissions.includes(item))) {
    //   return `/item/info/${id}?tab=${permissionTabs.find(item => listPermissions.includes(item))}`;
    // }
    return `/item/info/${id}`;
  },
};
