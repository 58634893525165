import { routerForgotPassword } from '@view/Auth/ForgotPassword/router';
import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerResetPassword } from '@view/Auth/ResetPassword/router';
import { routerCheckRouter } from '@view/CheckRouter/router';
import { routerCustomerInfo } from '@view/Customer/Pages/router';
import { routerCustomer } from '@view/Customer/router';
import { routerDashboard, routerHome } from '@view/Dashboard/router';
import { routerDeviceInfo } from '@view/Device/Pages/DeviceInfo/router';
import { routerFormDeviceAdd, routerFormDeviceEdit } from '@view/Device/Pages/FormDevice/router';
import { routerDevice } from '@view/Device/router';
import { routerErrors } from '@view/Errors/router';
import { routerGiftGiving } from '@view/Gift/component/OtherGifts/component/GiftGiving/router';
import { routerSortRankingGift } from '@view/Gift/component/RankingGifts/component/SortRankingGift/router';
import { routerAddGift, routerUpdateGift } from '@view/Gift/component/Share/AddGift/router';
import { routerGiftInfo } from '@view/Gift/component/Share/GiftInfo/router';
import {
  routerGift,
  routerOtherGift,
  routerRankingGift,
  routerVoucherImport
} from '@view/Gift/router';
import { routerPageError } from '@view/PageError/router';
import {
  routerFormProgrammeAdd,
  routerFormProgrammeEdit
} from '@view/Programme/Pages/FormProgramme/router';
import { routerProgrammeInfo } from '@view/Programme/Pages/PageInfo/router';
import { routerProgramme } from '@view/Programme/router';
import { routerRanking } from '@view/Ranking/router';
import { routerReport } from '@view/Report/router';
import { routerCategory } from '@view/Settings/Category/router';
import {
  routerAddCollection,
  routerCollection,
  routerCollectionInfo,
  routerEditCollection
} from '@view/Settings/Collection/router';
import { routerDeviceGroups } from '@view/Settings/DeviceGroups/router';
import { routerRankTimeSetting } from '@view/Settings/RankTimeSetting/router';
import { routerSettings } from '@view/Settings/router';
import { routerUsers } from '@view/Settings/Users/router';
import { routerVoucherInfo } from '@view/Voucher/Pages/PageInfo/router';
import { routerVoucher } from '@view/Voucher/router';

import { routerAddItem, routerItem, routerItemInfo, routerUpdateItem } from '../view/Item/router';
import { IRouter } from './interface';

export const privatePage: IRouter[] = [
  routerCheckRouter,
  routerHome,
  routerDashboard,
  routerCustomer,
  routerCustomerInfo,
  routerItem,
  routerProgramme,
  routerAddItem,
  routerItemInfo,
  routerUpdateItem,
  routerVoucher,
  routerDevice,
  routerDeviceInfo,
  routerFormDeviceAdd,
  routerFormDeviceEdit,
  routerGift,
  routerRanking,
  routerOtherGift,
  routerRankingGift,
  routerCategory,
  routerCollection,
  routerCollectionInfo,
  routerAddCollection,
  routerEditCollection,
  routerUsers,
  routerDeviceGroups,
  routerRankTimeSetting,
  routerErrors,
  routerGiftGiving,
  routerGiftInfo,
  routerUpdateGift,
  routerSortRankingGift,
  routerAddGift,
  routerReport,
  routerSettings,
  routerViewProfile,
  routerVoucherImport,
  routerFormProgrammeAdd,
  routerFormProgrammeEdit,
  routerProgrammeInfo,
  routerVoucherInfo,

  routerPageError,
];

export const publicPage: IRouter[] = [
  routerForgotPassword,
  routerResetPassword,
  routerLogin,
  routerPageError,
];
