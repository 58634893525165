export default {
  'device.router-name': 'Device',
  'device.title': 'Device management',
  'device-add.name': 'Add device',
  'device-form-add': 'Add device',
  'device-form-edit': 'Device update',
  'device-edit.name': 'Device update',
  'device.deviceCode': 'Device code',
  'device.deviceName': 'Device name',
  'device.province': 'Province/city',
  'device.district': 'District',
  'device.commune': 'Commune',
  'device.address': 'Installation address',
  'device.password': 'Password',
  'device.confirmPassword': 'Confirm password',
  'device.capacity': 'Capacity',
  'device.status': 'Operating status',
  'device.info.title': 'Device Information',
  'device.history.title': 'History of bottle collection',
  'device.history.fullBarrel.title': 'History is full of barrels',
  'device.history.collect.title': 'Container collection history',
  'device.history.fullBarrel.totalCans': 'Total number of cans',
  'device.history.fullBarrel.totalKg': 'Total kilograms',
  'device-fullBarrel.createdAt': 'Barrel filled time',
  'device-fullBarrel.numberOfBottles': 'Number of bottles',
  'device-fullBarrel.kgBottles': 'Kilograms of bottles',
  'device-fullBarrel.numberOfCans': 'Number of cans',
  'device-fullBarrel.kgCans': 'Kilograms of cans',
  'device-fullBarrel.totalKg': 'Total kilograms',
  'device-collect.createdAt': 'Collection time',
  'device-collect.numberOfBottles': 'Number of bottles',
  'device-collect.kgBottles': 'Kilograms of bottles',
  'device-collect.numberOfCans': 'Number of cans',
  'device-collect.kgCans': 'Kilograms of cans',
  'device-collect.totalKg': 'Total kilograms',
  'device-collect.collector.name': 'Collector',

  'device.code': 'Device code',
  'device.name': 'Device name',
  'device.specificAddress': 'Installation address',
  'device.obtainedQuantity': 'The number of bottles collected',
  'device.validQuantity': 'Number bottles of Aqua',
  'device.invalidQuantity': 'Other brand bottles',
  'device.bottlesCapacity': 'Bottles capacity',
  'device.cansCapacity': 'Cans capacity',
  'device.containerStatus': 'Capacity',
  'device.operationStatus': 'Operating status',
  'device.onlineStatus': 'Status',
  'device.action': 'Act',
  'device-info.operationStatus': 'Status',

  'device.longitude': 'Longitude',
  'device.latitude': 'Latitude',

  'device-info.name': 'Device Information',

  'device.capacity-empty': 'Empty container',
  'device.recycle-full': 'Full of trash',
  'device.water-full': 'Full of water',
  'device.bin-two-full': 'Full both bins',

  'device.status.containerStatus':
    '{status,select, 1 {Empty container} 2 {Full both bins} 3 {Full of trash} 4 {Full of water}  other {Unknown}}',
  'device.status.operationStatus': '{status,select, 1 {Active} 2 {Stop operation} other {Unknown}}',

  'device.history.time': 'Time',

  'device-info.total-aqua': 'Total number bottles of Aqua',
  'device-info.gift': 'Total bottle',
  'device-info.brand-different': 'The total number of other brand bottles',

  'device-info.time': 'Time',
  'device-info.invalidQuantity': 'The number of bottles of other brand',
  'device-info.validQuantity': 'The number of bottles is Aqua',
  'device-info.obtainedQuantity': 'Total bottle',
  'device-info.customer.nickName': 'Client',
  'device.content.delete': 'Are you sure you want to delete this device?',
  'device.title.delete': 'Delete the device',
  'device.placeholder.latitude': 'Please enter the latitude',
  'device.placeholder.longitude': 'Please enter the longitude',
  'errors.device.name': 'Device name',
  'errors.device': 'Device',
  'device.info.empty': 'You have no right to view device information',
  'device.error': 'Error',
  'device.error.status':
    '{status,select, 0 {Normal} 1 {Bottle pressing machine error} 2 {Bottle -release motor error} 3 {Bottle pressing machine error and bottle release motor error} 4 {Bottle grinding motor error} 5 {Bottle pressing machine error and bottle grinding motor error} 6 {Bottle release motor error and bottle grinding motor error} 7 {Bottle pressing machine and bottle -release motor error and a bottle grinding motor error} other {Unknown}}',
  'device.error.status.1': 'Bottle pressing motor',
  'device.error.status.2': 'Bottle release motor error',
  'device.error.status.3': 'Bottle crushing motor error ',
  'device.error.status.4': 'Bottle jamming',

  'deviceGroups.name': 'Device group',
  'deviceGroups.title': 'Device group management',
  'device-groups.delete.title': 'Delete the device group',
  'device-groups.delete.content': 'Are you sure you want to delete this device?',
  'device-groups.deviceName': 'Name of device group',
  'deviceGroups.information': 'Device group information',
  'deviceGroups.update': 'Device groups',
  'deviceGroups.create': 'Create device group',
  'device.deviceGroupId': 'Device group',
  'device.deviceGroup.name': 'Device group',
};
