import React from 'react';

import { OrderedListOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerRanking: IRouter = {
  path: '/ranking',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rankings.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <OrderedListOutlined />,
  },
  permissionCode: [PermissionEnum.WeeklyView, PermissionEnum.OverallView],

  generatePath(listPermissions: string[]) {
    const permissionTabs = [PermissionEnum.WeeklyView, PermissionEnum.OverallView];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/ranking?tab=${permissionTabs.find(item => listPermissions.includes(item))}`;
    }
    return '/ranking';
  },
};
