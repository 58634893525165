import React from 'react';

import { IRouter } from '@routers/interface';

export const routerProgrammeInfo: IRouter = {
  path: '/programme/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: true,
  name: 'programme-info.router.name', //translate here for breadcrumb and sidebar
};
