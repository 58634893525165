import React from 'react';

import { MacCommandOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerProgramme: IRouter = {
  path: '/programme',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'programme.name.router', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <MacCommandOutlined />,
  },
  permissionCode: PermissionEnum.EventsView,
};
