import React from 'react';

import { DatabaseOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerCategory: IRouter = {
  path: '/settings/category',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'category.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  icon: <DatabaseOutlined />,
  permissionCode: PermissionEnum.ItemGroupsView,
};
