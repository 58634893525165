import React from 'react';

import { IRouter } from '@routers/interface';

export const routerFormProgrammeAdd: IRouter = {
  path: '/programme/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'programme-add.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerFormProgrammeEdit: IRouter = {
  path: '/programme/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'programme-edit.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
